import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const FeaturedPostCard = ({ post }) => {
  const url = `/${post.slug}/`
  return (
    <Link className="FeaturedPostCard" to={url}>
      <article>
        <div className="FeaturedPostCard__ImageContainer">
          <Img fluid={post.local_feature_image.childImageSharp.fluid} durationFadeIn={200} loading="eager" className="FeaturedPostCard__Image" alt={post.title} />
        </div>
        <h3 className="FeaturedPostCard__Title">
          <span className="FeaturedPostCard__TitleText">{post.title}</span>
        </h3>
      </article>
    </Link>
  )
}

FeaturedPostCard.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    local_feature_image: PropTypes.object.isRequired
  }).isRequired,
}

export default FeaturedPostCard
