import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Content, BobaFacts, PostPreview, Layout } from '../components/common'
import { SectionHeader } from '../components/common'
import { FeaturedCards } from '../components/common/featured'
import { MetaData } from '../components/common/meta'

import config from '../utils/siteConfig'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location }) => {
  const posts = data.allGhostPost.edges

  return (
    <>
      <MetaData location={location} />
      <Layout>
        <SectionHeader sectionTitle="Boba guides" />
        <FeaturedCards />
        <section className="Index__LatestNotesContainer">
          <SectionHeader sectionTitle="Latest notes" />
          {posts.filter(({ node }, index) => index < config.numFullNotesOnIndex).map(({ node }, index) => {
            return <Content key={node.id} content={node} isOnHome/>
          })}
        </section>
        <SectionHeader sectionTitle="Boba facts" />
        <BobaFacts />
        {posts.length > config.numFullNotesOnIndex && 
          <><SectionHeader sectionTitle="Older notes" />
          <div className="Index__OlderNotesContainer">
            {posts.filter(({ node }, index) => index >= config.numFullNotesOnIndex).map(({ node }, index) => {
              return <PostPreview key={node.id} post={node} loading={index == 0 ? "eager" : "lazy"}/>
            })}
          </div></>
        }
      </Layout>
    </>
  )
}

Index.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostIndexQuery($limit: Int!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        filter: { featured: { eq: false } },
        limit: $limit
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
