import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'

import FeaturedPostCard from './FeaturedPostCard'

const FeaturedCards = ({ data }) => {
  const posts = data.allGhostPost.edges;

  return (
    <section className="FeaturedCards">
      <div className="FeaturedCards__Container">
        {posts.map(({ node }) => (
        // The tag below includes the markup for each post - components/common/PostCard.js
          <FeaturedPostCard key={node.id} post={node} />
        ))}
      </div>
    </section>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: { featured: { eq: true } },
            limit: 4
        ) {
          edges {
            node {
              id,
              local_feature_image {
                childImageSharp{
                  fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      presentationWidth
                  }
                }
              },
              title,
              slug
            }
          }
        }
      }
    `}
    render={data => <FeaturedCards data={data} {...props} />}
  />
)

FeaturedCards.propTypes = {
  data: PropTypes.shape({
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired
}
